'use strict';

const PayPalExpressModel = require('./payPalExpressModel');

/**
 * PayPal cart model constructor
 * @param {Object} alertHandlerModel alert handler model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {Object} $csrfToken CSRF token js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalCartModel(alertHandlerModel,
                         btClientInstancePromise,
                         payPalButtonSelector,
                         payPalConfigurations,
                         $csrfToken,
                         $loaderContainer) {
    // Init PayPalExpressModel constructor
    PayPalExpressModel.call(this,
        alertHandlerModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        $csrfToken,
        $loaderContainer);

    this.changePMButtonEnabled = payPalConfigurations.changePMButtonEnabled;
}

// ES5 inheritance
PayPalCartModel.prototype = Object.create(PayPalExpressModel.prototype);

// Show error in case if current basket has zero total price
PayPalCartModel.prototype.onPayPalButtonClickCallback = async function(_, actions) {
    await PayPalExpressModel.prototype.onPayPalButtonClickCallback.call(this, _, actions);
    let action;

    if (this.basketData.amount === 0) {
        this.alertHandlerModel.showZeroAmountError();

        action = actions.reject();
    }

    return action;
};

module.exports = PayPalCartModel;
