'use strict';

/* global braintreeUtils braintree $ */

const googlePaySDKHelper = require('../helpers/googlePaySDKHelper');

let btClientInstancePromise;

/**
 * Creates googlePayment instance
 * @param {string} googleMerchantId Google Pay merchant identifier
 * @returns {Promise} A promise resolve with with the GooglePaymnet instance
 */
function createGooglePayment(googleMerchantId) {
    return btClientInstancePromise
        .then(function(btClientInstance) {
            const googlePayOptions = googlePaySDKHelper.createGooglePayOptions(btClientInstance,
                googleMerchantId);

            return braintree.googlePayment.create(googlePayOptions);
        });
}

/**
 * Creates a configuration object for use in the loadPaymentData method.
 * @param {googlePaymentInstance} googlePaymentInstance Google Pay instance
 * @param {Object} options Object with payment options
 * @param {number} amount Order total amount
 * @returns {Object} Configuration object
 */
function createPaymentDataRequest(googlePaymentInstance, options, amount) {
    const requestObject = {
        transactionInfo: {
            currencyCode: options.currency,
            totalPriceStatus: 'FINAL',
            totalPrice: String(amount)
        },
        merchantInfo: {
            merchantName: options.displayName
        },
        shippingAddressRequired: options.isShippingAddressRequired,
        shippingAddressParameters: {
            allowedCountryCodes: options.countries,
            phoneNumberRequired: true
        },
        emailRequired: true,
        callbackIntents: ['PAYMENT_AUTHORIZATION']
    };

    if (options.isShippingOptionRequired) {
        requestObject.shippingOptionRequired = options.isShippingOptionRequired;
        requestObject.shippingOptionParameters = {
            defaultSelectedOptionId: googlePaySDKHelper.getSelectedShippingId(),
            shippingOptions: options.shippingOptions
        };
        requestObject.callbackIntents.push('SHIPPING_ADDRESS', 'SHIPPING_OPTION');
    }

    if (options.merchantId) {
        requestObject.merchantInfo.merchantId = options.merchantId;
    }

    return googlePaymentInstance.createPaymentDataRequest(requestObject);
}

/**
 * Parse the response from the tokenization.
 * @param {Object} paymentData The response back from the Google Pay tokenization.
 * @param {googlePaymentInstance} googlePaymentInstance Google Pay instance
 * @returns {Promise} Promise that resolve with a tokenizePayload
 */
function parseResponse(paymentData, googlePaymentInstance) {
    return googlePaymentInstance.parseResponse(paymentData);
}

/**
 * Inits braintreeGooglePaySDK with client instance promise
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 */
function init(braintreeClientInstancePromise) {
    btClientInstancePromise = braintreeClientInstancePromise;
}

module.exports = {
    createGooglePayment,
    createPaymentDataRequest,
    parseResponse,
    init
};
