'use strict';

const braintreeCreditCardModel = require('./braintreeCreditCardModel');
const braintreeGeneral = require('../../braintreeGeneral');
const creditCardHelper = require('../helpers/creditCardHelper');

/**
 * Initialize Braintree Credit Card Model. Pattern Builder is used
 * @returns {Object} object with booleam "error" flag and "message" attribute with error message
 */
function init() {
    const braintreeCreditCardConfigs = creditCardHelper.getCheckoutBraintreeCreditCardConfigs();
    const response = {
        error: false,
        message: '',
        btCreditCardModel: null,
        btCreditCardConfigs: null
    };

    let clientToken;
    let btClientInstancePromise;
    let is3dSecureEnabled;
    let is3dSecureFallback;
    let isFraudToolsEnabled;

    if (braintreeCreditCardConfigs) {
        clientToken = braintreeCreditCardConfigs.clientToken;
        btClientInstancePromise = braintreeGeneral.createClientInstance(clientToken);
        is3dSecureEnabled = braintreeCreditCardConfigs.is3dSecureEnabled;
        is3dSecureFallback = braintreeCreditCardConfigs.is3dSecureFallback;
        isFraudToolsEnabled = braintreeCreditCardConfigs.isFraudToolsEnabled;

        braintreeCreditCardModel.init(btClientInstancePromise,
            is3dSecureEnabled,
            isFraudToolsEnabled,
            is3dSecureFallback);

        response.btCreditCardModel = braintreeCreditCardModel;
        response.btCreditCardConfigs = braintreeCreditCardConfigs;
    } else {
        response.error = true;
        response.message = 'No Credit Card Braintree Configurations';
    }

    return response;
}

module.exports = {
    init
};
