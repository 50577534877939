'use strict';

const braintreeGeneral = require('../braintreeGeneral');
const srcHelper = require('./helpers/srcHelper');

// Components
const srcAccount = require('./components/srcAccount');
const srcCardList = require('./components/srcList');
const checkoutComponent = require('./components/srcCheckout');
const cartComponent = require('./components/srcCart');

let btClientInstancePromise;

/**
 * Inits SRC functionality on the Account Page
 */
function initAccount() {
    srcAccount.init(btClientInstancePromise);
}

/**
 * Inits SRC functionality on the Cart Page
 */
function initCartCheckout() {
    cartComponent.init(btClientInstancePromise);
}

/**
 * Inits SRC functionality on the Billing Page
 */
function initBillingCheckout() {
    checkoutComponent.init(btClientInstancePromise);
    srcCardList.init();
}

/**
 * Inits braintreeSrcModelBuilder on SRC level
 */
function init() {
    const braintreeSrcConfigs = srcHelper.getBraintreeSRCButtonConfigs();
    let clientToken;

    if (braintreeSrcConfigs) {
        clientToken = braintreeSrcConfigs.clientToken;
        btClientInstancePromise = braintreeGeneral.createClientInstance(clientToken);
    }
}

module.exports = {
    initAccount,
    initCartCheckout,
    initBillingCheckout,
    init
};
