'use strict';

/**
 * Inits credit card functionality on Storefront
 * @returns {void}
 */
function initCreditCardFunctionality() {
    const creditCardOnAccountEnabled = Boolean(document.querySelector('.js-braintree-add-credit-card-form'));
    const creditCardEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-credit-card-content'));

    let creditCardBusinessLogic;

    if (creditCardOnAccountEnabled || creditCardEnabledOnCheckout) {
        creditCardBusinessLogic = require('./creditcard/creditCard');
        creditCardBusinessLogic.init();
    }

    if (creditCardOnAccountEnabled) {
        creditCardBusinessLogic.initAccount();
    }

    if (creditCardEnabledOnCheckout) {
        creditCardBusinessLogic.initCheckout();
    }
}

/**
 * Inits PayPal functionality on Storefront
 * @param {Promise} clientTokenPromise promise with Braintree Client Instance
 * @returns {void}
 */
async function initPayPalFunctionality(clientTokenPromise) {
    const payPalBusinessLogic = require('./paypal/payPal');

    const payPalEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-paypal-content'));
    const payPalEnabledOnProduct = Boolean(document.querySelector('.prices-add-to-cart-actions .js-braintree-paypal-cart-button'));
    const payPalEnabledOnPvp = Boolean(document.querySelector('#quickViewModal .js-braintree-paypal-cart-button'));
    const payPalEnabledOnCart = Boolean(document.querySelector('.cart-page .js-braintree-paypal-cart-button'));
    const payPalEnabledOnAccountPage = Boolean(document.querySelector('.js-paypal-accounts'));
    const payPalEnabledOnLoginPage = Boolean(document.querySelector('.login-form-nav'));
    const cwppEnabledOnPage = Boolean(document.getElementById('js-cwpp-button'));
    const payPalBannerOnCategory = Boolean(document.querySelector('.js-category-banner'));

    payPalBusinessLogic.init(clientTokenPromise);

    const availablePages = [
        payPalEnabledOnProduct, payPalEnabledOnCart,
        payPalEnabledOnCheckout, payPalEnabledOnLoginPage,
        payPalEnabledOnAccountPage, cwppEnabledOnPage,
        payPalEnabledOnPvp
    ];

    if (availablePages.includes(true)) {
        const PayPalScriptLoading = require('./paypal/models/payPalScriptLoading');
        const cwpp = require('./paypal/components/connectWithPayPal.js');

        const payPalScriptLoading = new PayPalScriptLoading();

        if (cwpp.isContainerExist()) {
            await payPalScriptLoading.loadPayPalSDK();

            payPalScriptLoading.loadConnectWithPayPal(cwpp.init);
        }
    }

    if (payPalBannerOnCategory) {
        const PayPalScriptLoading = require('./paypal/models/payPalScriptLoading');
        const payPalScriptLoading = new PayPalScriptLoading();

        payPalScriptLoading.loadPayPalCreditMessages();
    }

    if (payPalEnabledOnCheckout) {
        payPalBusinessLogic.initCheckout();
    }

    if (payPalEnabledOnProduct) {
        payPalBusinessLogic.initProduct();
    }

    if (payPalEnabledOnPvp) {
        payPalBusinessLogic.initQuickView();
    }

    // Validation on enabled/disabled PayPal button for MiniCart located isinside MiniCart component
    payPalBusinessLogic.initMiniCart();

    if (payPalEnabledOnCart) {
        payPalBusinessLogic.initCart();
    }

    if (payPalEnabledOnAccountPage) {
        payPalBusinessLogic.initAccount();
    }
}

/**
 * Inits GooglePay functionality on Storefront
 * @returns {void}
 */
function initGooglePayFunctionality() {
    const googlePayBusinnessLogic = require('./googlepay/googlePay');

    const googlePayEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-billing-googlepay-button'));
    const googlePayEnabledOnCart = Boolean(document.querySelector('.js-braintree-cart-googlepay-button'));

    googlePayBusinnessLogic.init();

    if (googlePayEnabledOnCheckout) {
        googlePayBusinnessLogic.initCheckout();
    }

    if (googlePayEnabledOnCart) {
        googlePayBusinnessLogic.initCart();
    }
}

/**
 * Inits ApplePay functionality on Storefront
 * @returns {void}
 */
function initApplePayFunctionality() {
    const applePayBusinessLogic = require('./applepay/applePay');

    const applePayEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-applepay-content'));
    const applePayEnabledOnCart = Boolean(document.querySelector('.js-braintree-applepay-button'));

    applePayBusinessLogic.init();

    if (applePayEnabledOnCart || applePayEnabledOnCheckout) {
        applePayBusinessLogic.initApplePay();
    }
}

/**
 * Inits Venmo functionality on Storefront
 * @returns {void}
 */
function initVenmoFunctionality() {
    const venmoBusinessLogic = require('./venmo/venmo');

    const isVenmoEnabledOnAccountPage = Boolean(document.querySelector('.js-venmo-accounts'));
    const isVenmoEnabledOnBillingPage = Boolean(document.querySelector('.js-braintree-venmo-content'));

    if (isVenmoEnabledOnAccountPage || isVenmoEnabledOnBillingPage) {
        venmoBusinessLogic.init();
    }

    if (isVenmoEnabledOnAccountPage) {
        venmoBusinessLogic.initAccount();
    }

    if (isVenmoEnabledOnBillingPage) {
        venmoBusinessLogic.initBillingCheckout();
    }
}

/**
 * Inits SRC functionality on Storefront
 * @returns {void}
 */
function initSRCFunctionality() {
    const srcBusinessLogic = require('./src/src');

    const isSrcEnabledOnAccountPage = Boolean(document.querySelector('.js-braintree-account-add-src'));
    const isSrcEnabledOnCartPage = Boolean(document.querySelector('.js-braintree-cart-src-button'));
    const isSrcEnabledOnBillingPage = Boolean(document.querySelector('.js-braintree-billing-src-button'));

    srcBusinessLogic.init();

    if (isSrcEnabledOnAccountPage) {
        srcBusinessLogic.initAccount();
    }

    if (isSrcEnabledOnCartPage) {
        srcBusinessLogic.initCartCheckout();
    }

    if (isSrcEnabledOnBillingPage) {
        srcBusinessLogic.initBillingCheckout();
    }
}

/**
 * Inits LPM functionality on Storefront
 * @returns {void}
 */
function initLPMFunctionality() {
    const lpmBusinessLogic = require('./local/lpm');
    const isLpmEnebledOnBillingPage = Boolean(document.querySelector('.js-braintree-lpm-content'));

    if (isLpmEnebledOnBillingPage) {
        lpmBusinessLogic.init();
    }

    // LPM fallback process
    if (document.querySelector('.js-lpm-fallback')) {
        const lpmFallback = require('./local/lpmFallBack');

        lpmFallback.process();
    }
}

/**
 * Inits APMA functionality on Storefront
 * @returns {void}
 */
function initAPMAFunctionality() {
    const apmaBusinessLogic = require('./apma');
    const isApmaEnebled = Boolean(document.getElementById('js-apma-button-paypal'));

    if (isApmaEnebled) {
        apmaBusinessLogic.init();
    }
}

/**
 * Handle payment`s errors on checkout page
 * @returns {void}
 */
function handlePaymentMethodErrorCheckout() {
    const helper = require('./helper');

    $(document).on('ajaxSuccess', function(event, jqXHR, ajaxSettings, response) {
        if (response.error && response.action === 'CheckoutServices-PlaceOrder') {
            helper.removeActiveSessionPayment(true);
        }
    });
}

module.exports = async function() {
    // Used only for Paypal. In future "client token" generation approach will be refactored
    const paymentMethodGeneral = require('./paymentMethodGeneral');

    // General global variables
    const isCheckoutPage = Boolean(document.getElementById('checkout-main'));
    const isAccountPage = Boolean(document.querySelector('.js-account-dashboard'));

    /* ------------------------General Account functionality------------------------ */
    if (isAccountPage) {
        const btAccountBusinessLogic = require('./braintreeAccount');
        // Common Credit Card and SRC Account functionality

        btAccountBusinessLogic.initAccount();
    }

    /* ------------------------General Checkout functionality----------------------- */
    if (isCheckoutPage) {
        paymentMethodGeneral.fillPaymentSummaryContainer();
        paymentMethodGeneral.initPaymentMethodTabClickBehavior();
        paymentMethodGeneral.initChangeShippingBehavior();
        paymentMethodGeneral.initBillingSelectorChangeFunctionality();
        paymentMethodGeneral.initAmountObserver();
        handlePaymentMethodErrorCheckout();

        const lastAmount = parseFloat(window.sessionStorage.getItem('amount-used'));

        if ((window.braintreePreferences.is3DSecureEnabled
            || window.braintreePreferences.is3DSecureFallback) && lastAmount) {
            paymentMethodGeneral.handleActiveCCSessionPayment(lastAmount);
        }
    }

    /* ------------------------Credit Card------------------------ */

    initCreditCardFunctionality();

    /* --------------------------PayPal-------------------------- */

    $('body').on('quickview:ready', async() => {
        await initPayPalFunctionality(paymentMethodGeneral.createGeneralClientInstancePromise());
    });

    await initPayPalFunctionality(paymentMethodGeneral.createGeneralClientInstancePromise());

    /* --------------------------GooglePay------------------------- */

    initGooglePayFunctionality();

    /* --------------------------ApplePay------------------------- */

    initApplePayFunctionality();

    /* --------------------------Venmo---------------------------- */

    initVenmoFunctionality();

    /* --------------------------SRC------------------------------ */

    initSRCFunctionality();

    /* --------------------------LPM-------------------------- */

    initLPMFunctionality();

    /* --------------------------APMA-------------------------- */

    initAPMAFunctionality();
};
