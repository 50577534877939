'use strict';

const AlertHandlerModel = require('../../alertHandler');

/**
 * Paypal alert handler model
 */
class PaypalAlertHandlerModel extends AlertHandlerModel {
    constructor(alertMessages) {
        super();
        this.alertMessages = alertMessages;
    }

    /**
     * Show "Billing Address is not Supported" error
     */
    showBillingAddressIsNotSupportedError() {
        this.showError(this.alertMessages.PAYPAL_BILLING_ADDRESS_NOT_SUPPORTED);
    }

    /**
     * Shows "Zero amount" error
     */
    showZeroAmountError() {
        this.showError(this.alertMessages.CUSTOM_PAYPAL_ZERO_AMOUNT_ERROR);
    }
}

module.exports = PaypalAlertHandlerModel;
