'use strict';

const srcHelper = require('../helpers/srcHelper');
const srcListHelper = require('../helpers/srcListHelper');

/**
 * Inits SRC list functionality
 */
function init() {
    const $srcAccountList = document.getElementById('braintreeSrcAccountsList');
    const srcListExist = Boolean($srcAccountList);

    if (srcListExist) {
        srcListHelper.srsListStateHandler();

        $srcAccountList.addEventListener('change', function() {
            srcHelper.srcContentTabHandler(true);
        });
    }
}

module.exports = {
    init
};
