'use strict';

const helper = require('../../helper');
const loaderInstance = require('../../loaderHelper');
const venmoHelper = require('../helpers/venmoHelper');
const VenmoAlertHandlerModel = require('../alerthandler/venmoAlertHandlerModel');
const alertHandler = new VenmoAlertHandlerModel();

const $venmoButton = document.querySelector('.js-braintree-account-venmo-button');

let venmoLoader;

/**
 * Makes Venmo account as default one
 * @param {Event} e event to identify target element
 */
function makeDefaultVenmo(e) {
    const target = e.target;
    const url = window.braintreeUrls.makePaymentMethodDefaultUrl;
    const id = target.getAttribute('data-id');
    const paymentMethodID = window.braintreePreferences.paymentMethods.BRAINTREE_VENMO.paymentMethodId;
    const $loaderContainter = document.querySelector(`#${target.getAttribute('data-loader')}`);
    const loader = loaderInstance($loaderContainter);

    alertHandler.hideAlerts();
    loader.show();

    helper.setDefaultProperty({
        url: url,
        id: id,
        paymentMethodID: paymentMethodID,
        loader: loader
    });
}

/**
 * Remove Venmo account
 * @param {Event} e event to identify target element
 */
function removeVenmoPayment(e) {
    const target = e.target;
    const $loaderContainter = document.getElementById(target.getAttribute('data-loader'));

    venmoLoader = loaderInstance($loaderContainter);
    venmoLoader.show();

    fetch(`${window.braintreeUrls.deletePaymentUrl}?UUID=${target.getAttribute('data-id')}`, { method: 'GET' })
        .then((response) => {
            return response.json();
        })
        .then((data => {
            if (data.error && data.error.message) {
                // Show error message in a case of current PI removal is not allowed
                alertHandler.showError(data.error.message);
            } else {
                document.getElementById(`uuid-${data.UUID}`).remove();

                if (data.newDefaultAccount) {
                    document.getElementById(`uuid-${data.newDefaultAccount}`).classList.add('font-weight-bold');
                    document.getElementById(`uuid-${data.newDefaultAccount}`).classList.add('d-none');
                }
            }

            venmoLoader.hide();
        }))
        .catch(() => {
            location.reload();
            venmoLoader.hide();
        });
}

/**
 * Creates Venmo form data on account page
 * @param {string} nonce Braintree Venmo nonce
 * @param {string} userId Braintree Venmo userID
 * @returns {Object} object with Venmo form data
 */
function createVenmoAccountFormData(nonce, userId) {
    const venmoAccountFormFields = $venmoButton.getAttribute('data-venmo-account-form-fields');
    const csrfToken = document.querySelector('.js-braintree-account-venmo-button-wrapper #csrf_token');

    const venmoAccountFormData = helper.createPaymentFormData(venmoAccountFormFields, {
        nonce: nonce,
        userId: userId
    });

    venmoAccountFormData.append(csrfToken.name, csrfToken.value);

    return venmoAccountFormData;
}

/**
 * Stores new Venmo account
 * @param {Object} data tokenized payload data with Venmo nonce
 * @returns {Promise} call to Braintree-AccountAddVenmoHandle endpoint
 */
function storeNewVenmoAccount(data) {
    const venmoAddAccountHandlerUrl = $venmoButton.getAttribute('data-venmo-add-account-handler');
    const venmoAccountFormData = createVenmoAccountFormData(data.nonce, data.details.username);
    const encodedFormData = btoa(JSON.stringify(Object.fromEntries(venmoAccountFormData)));

    venmoLoader.show();

    return fetch(venmoAddAccountHandlerUrl, {
        method: 'POST',
        body: encodedFormData,
        headers: { 'Content-Type': 'application/json' }
    })
        .then((response) => response.json())
        .then((paymentData) => {
            venmoLoader.hide();
            if (paymentData.error) {
                alertHandler.showError(paymentData.error);
            } else {
                venmoHelper.hideVenmoAccountBtn();
                fetch(paymentData.renderAccountsUrl)
                    .then((template) => template.text())
                    .then((templateHTML => {
                        document.querySelector('.js-venmo-accounts').innerHTML = templateHTML;
                        document.querySelectorAll('.js-venmo-accounts .js-remove-bt-payment').forEach((el) => {
                            el.addEventListener('click', removeVenmoPayment);
                        });
                    }));
            }
        });
}

/**
 * Inits Venmo functionality
 * @param {Object} btVenmoModel Braintree Venmo model
 */
function init(btVenmoModel) {
    const isVenmoButtonEnabledOnAccountPage = Boolean(document.querySelector('.js-braintree-account-venmo-button'));
    const isVenmoAccountBlockExists = Boolean(document.querySelector('.js-venmo-accounts'));
    const $braintreeVenmoLoader = document.getElementById('braintreeVenmoAccLoader');

    venmoLoader = loaderInstance($braintreeVenmoLoader);

    if (isVenmoButtonEnabledOnAccountPage || isVenmoAccountBlockExists) {
        // "Make default" button functionality initiation
        document.querySelectorAll('.js-venmo-accounts .js-braintree-make-default-card')
            .forEach((el) => el.addEventListener('click', makeDefaultVenmo));
        // "Remove" button functionality initiation
        document.querySelectorAll('.js-venmo-accounts .js-remove-bt-payment')
            .forEach((el) => el.addEventListener('click', removeVenmoPayment));
    }

    if (isVenmoButtonEnabledOnAccountPage) {
        const $addVenmoAccountButton = document.querySelector('.js-add-venmo-account');

        $addVenmoAccountButton.addEventListener('click', function() {
            const venmoButton = require('../components/venmoButton');
            // Hide venmo account error container

            alertHandler.hideAlerts();
            venmoHelper.showVenmoAccountBtn();

            if (!window.venmoAccountButtonInited) {
                window.venmoAccountButtonInited = true;

                venmoButton.init(btVenmoModel, $venmoButton);
            }
        });
    }
}

module.exports = {
    init,
    storeNewVenmoAccount
};
