'use strict';

const helper = require('../../helper');

const $addVenmoAccountBtn = document.querySelector('.js-add-venmo-account');
const $venmoAccountWrapper = document.querySelector('.js-braintree-account-venmo-button-wrapper');

const btVenmoButtonClassName = '.js-braintree-venmo-button';

/**
 * Returns Braintree Venmo configs
 * @returns {Object} Braintree Venmo configs
 */
function getBraintreeVenmoButtonConfigs() {
    const $venmoButton = document.querySelector(`${btVenmoButtonClassName}, .js-braintree-account-venmo-button`);

    return helper.tryParseJSON($venmoButton.getAttribute('data-braintree-config'));
}

/**
 * Creates loader container on the Billing page
 * @param {Element} $target Venmo button target for which loader container is created
 * @returns {Element} created loader container element
 */
function createLoaderContainter($target) {
    const $loaderContainter = document.createElement('div');

    $loaderContainter.classList.add('braintree-venmo-loader', 'js-braintree-venmo-loader');

    helper.continueButtonToggle(true);

    return $target.parentNode.insertBefore($loaderContainter, $target);
}

/**
 * Updates the billing address form values within saved billing
 * @param {Object} billingAddress billing address
 */
function updateBillingAddressFormValues(billingAddress) {
    const form = document.querySelector('form[name=dwfrm_billing]');
    const inputNames = ['firstName', 'lastName', 'address1', 'address2', 'city', 'postalCode'];

    if (!form) {
        return;
    }

    inputNames.forEach((value) => {
        form.querySelector(`input[name$=_${value}]`).value = decodeURIComponent(billingAddress[value]);
    });

    // Process the billing address select elements (Country, State)
    ['select[name$=_stateCode]', 'input[name$=_stateCode]', 'select[name$=_country]', 'input[name$=_country]']
        .forEach((elemSelector) => {
            const $elem = form.querySelector(elemSelector);

            if ($elem) {
                $elem.value = elemSelector.includes('stateCode') ? billingAddress.stateCode : billingAddress.country;
            }
        });
}

/**
 * Shows Venmo button on the Account Page and hides "add new" button
 */
function showVenmoAccountBtn() {
    $addVenmoAccountBtn.classList.add('d-none');
    $venmoAccountWrapper.classList.remove('d-none');
}

/**
 * Hides Venmo button on the Account Page and shows "add new" button
 */
function hideVenmoAccountBtn() {
    $addVenmoAccountBtn.classList.remove('d-none');
    $venmoAccountWrapper.classList.add('d-none');
}

/**
 * Saves Venmo device (risk) data to hidden input
 * @param {string} deviceData Venmo device (risk) data
 */
function setVenmoDeviceData(deviceData) {
    const $braintreeVenmoDeviceData = document.getElementById('braintreeVenmoDeviceData');

    if ($braintreeVenmoDeviceData) {
        $braintreeVenmoDeviceData.value = deviceData;
    }
}

/**
 * Checks billing form on the Billing Page
 * If billing form is valid, saves/updates billing address to input
 * If not valid (possible case: empty email) - add error class to email input
 * @returns {boolean} billing form check result value
 */
function checkBillingForm() {
    const $billingForm = document.querySelector('.card-body [id=dwfrm_billing]');
    const $venmoBillingInput = document.getElementById('braintreeVenmoBilling');

    if ($billingForm.checkValidity()) {
        /**
            * Setting current customer Billing address to form
            * For the case when SFCC redirects customer from payment stage to shipping stage
            * after successful Venmo app response
        */
        $venmoBillingInput.value = JSON.stringify(helper.getBillingAddressFormValues());

        return true;
    }

    return false;
}

/**
 * Checks zero amount on the Billing Page
 * If amount is zero Venmo pop-up is closed and appropriate error is shown
 * If amount is acceptable by Venmo user could proceed through pop-up
 * @returns {boolean} zero amount check result value
 */
function checkZeroAmount() {
    const $venmoButton = document.querySelector(btVenmoButtonClassName);
    const braintreeVenmoConfig = helper.tryParseJSON($venmoButton.getAttribute('data-braintree-config'));
    const amount = braintreeVenmoConfig.options.amount;

    if (amount === 0) {
        const VenmoAlertHandlerModel = require('../alerthandler/venmoAlertHandlerModel');
        const alertHandler = new VenmoAlertHandlerModel();

        alertHandler.showError(braintreeVenmoConfig.messages.VENMO_ZERO_AMOUNT_ERROR);

        return true;
    }

    return false;
}

/**
 * Handles all behavior related to the Venmo content tab
 */
function venmoContentTabHandler() {
    const $btVenmoAccountsList = document.getElementById('braintreeVenmoAccountsList');
    const $saveVenmoAccountCheckboxContainer = document.getElementById('braintreeSaveVenmoAccountContainer');
    const $saveVenmoAccountCheckbox = document.getElementById('braintreeSaveVenmoAccount');
    const $venmoButton = document.querySelector(btVenmoButtonClassName);
    const $venmoContent = document.querySelector('.js-braintree-venmo-content');

    const venmoOptionId = $btVenmoAccountsList.value;

    if (venmoOptionId === 'newaccount') {
        // Case when option "new account" is selected in dropdown
        $venmoButton.style.display = 'block';

        helper.continueButtonToggle(true);
        $venmoContent.setAttribute('data-paypal-is-hide-continue-button', true);
        // Case when registered user and vaulting is enabled
        if ($saveVenmoAccountCheckboxContainer) {
            $saveVenmoAccountCheckbox.checked = true;
            $saveVenmoAccountCheckboxContainer.style.display = 'block';
        }
    } else {
        // Case when saved account is selected in dropdown
        $venmoButton.style.display = 'none';

        helper.continueButtonToggle(false);
        $venmoContent.setAttribute('data-paypal-is-hide-continue-button', false);
        // Case when registered user and vaulting is enabled
        if ($saveVenmoAccountCheckboxContainer) {
            $saveVenmoAccountCheckbox.checked = false;
            $saveVenmoAccountCheckboxContainer.style.display = 'none';
        }
    }
}

module.exports = {
    getBraintreeVenmoButtonConfigs,
    createLoaderContainter,
    updateBillingAddressFormValues,
    showVenmoAccountBtn,
    hideVenmoAccountBtn,
    setVenmoDeviceData,
    checkBillingForm,
    checkZeroAmount,
    venmoContentTabHandler
};
