'use strict';

const helper = require('../../helper');

function showPayPalStaticImageButton() {
    const $staticImage = document.getElementById('js-cwpp-static-image');

    if ($staticImage) {
        $staticImage.classList.remove('d-none');
    }
}

function isContainerExist() {
    if (!document.getElementById('js-cwpp-button')) {
        showPayPalStaticImageButton();

        return false;
    }

    return true;
}

/**
 * Connect with PayPal
 * @link {https://developer.paypal.com/docs/log-in-with-paypal/integrate/generate-button}
 */
function initConnectWithPayPal() {
    const $container = document.getElementById('js-cwpp-button');

    if (!isContainerExist()) {
        return;
    }

    const MIN_NUMBER_OF_PARAMETERS = 8;
    const parameters = helper.tryParseJSON($container.getAttribute('data-parameters'));

    if (!(window.paypal && Object.keys(parameters).length > MIN_NUMBER_OF_PARAMETERS)) {
        showPayPalStaticImageButton();

        return;
    }

    window.paypal.use(['login'], function(login) {
        login.render(parameters);
    });
}

/**
 * Function which initiate PayPal functionality on the Login Page
 */
function init() {
    initConnectWithPayPal();
}

module.exports = {
    init,
    isContainerExist
};
