'use strict';

const helper = require('../../helper');
const paymentMethodGeneral = require('../../paymentMethodGeneral');
const $applePayButton = document.querySelector('.js-braintree-applepay-button');

let paymentData;
let $braintreeCartButtons;
let applePayCheckoutFormData;
let alertHandler;

/**
 * Expends Apple Pay Checkout Form with required data
 */
function expandApplePayCheckoutFormData() {
    const $csrfToken = document.querySelector('.js-braintree-cart-buttons-wrap #csrf_token');

    applePayCheckoutFormData.append($csrfToken.name, $csrfToken.value);
    applePayCheckoutFormData.append('braintreeApplePayNonce', paymentData.nonce);
    applePayCheckoutFormData.append('braintreeApplePayDeviceDataInput', paymentData.deviceData);
    applePayCheckoutFormData.append('braintreeApplePayShippingAddress',
        JSON.stringify(paymentData.shippingAddress) || '{}');
    applePayCheckoutFormData.append('braintreeApplePayBillingAddress',
        JSON.stringify(paymentData.billingAddress) || '{}');
}

/**
 * Process Apple Pay on the Cart Page
 */
function processCartFlow() {
    const billingAddressData = paymentData.billingAddress;
    const paymentMethodName = helper.tryParseJSON($applePayButton.getAttribute('data-braintree-config')).paymentMethodName;
    const placeOrderUrl = window.braintreeUrls.placeOrderUrl;
    const checkoutSubmitPayment = window.braintreeUrls.checkoutSubmitPayment;
    const checkoutFormFields = $braintreeCartButtons.getAttribute('data-checkout-form-fields');
    const paymentFieldData = helper.getPaymentFieldsData(billingAddressData, paymentMethodName);

    applePayCheckoutFormData = helper.createPaymentFormData(checkoutFormFields, paymentFieldData);
    applePayCheckoutFormData.append('pageFlowCart', true);
    expandApplePayCheckoutFormData();
    // submit customer form with email (CheckoutServices-SubmitCustomer)
    // as we skip step "login via guest/registered user" while express checkout
    // email is set only in case of guest checkout and if email is not already set
    paymentMethodGeneral.submitCustomerForm(billingAddressData.email);

    fetch(checkoutSubmitPayment, {
        method: 'POST',
        body: applePayCheckoutFormData
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                let errorMessage = '';

                if (data.fieldErrors.length) {
                    data.fieldErrors.forEach(function(error, index) {
                        const keys = Object.keys(error);

                        if (keys.length) {
                            errorMessage += `${keys[index].replace('dwfrm_billing_', '')
                                .replace('_', ' ')} ${data.fieldErrors[index][keys[index]]}. `;
                        }
                    });
                    alertHandler.handleApplePayError(errorMessage);
                }

                if (data.serverErrors.length) {
                    data.serverErrors.forEach(function(error) {
                        errorMessage += `${error}. `;
                    });
                    alertHandler.handleApplePayError(errorMessage);
                }

                if (data.cartError) {
                    window.location.href = data.redirectUrl;
                }

                return;
            }

            window.location.href = placeOrderUrl;
        })
        .catch((error) => {
            if (error && error.redirectUrl) {
                window.location.href = error.redirectUrl;
            }
        });
}

/**
 * Inits Apple Pay Cart components
 * @param {Object} data Apple Pay payment data
 * @param {Constructor} alertHandlingModel Alert handling model
 */
function init(data, alertHandlingModel) {
    paymentData = data;
    $braintreeCartButtons = document.querySelector('.js-braintree-cart-buttons-wrap');
    $applePayButton.setAttribute('data-is-inited', true);
    alertHandler = alertHandlingModel;

    processCartFlow();
}

module.exports = {
    init
};
