'use strict';

// File for form fields validation (applying red, green, yellow frame)
// This file is used ONLY for validation of Owner field (since Owner field isn't a Hosted Field provided by Braintree client SDK)

const creditCardFormFieldsHelper = require('../helpers/creditCardFormFieldsHelper');

const BRAINTREE_ERROR_SELECTOR = 'braintree-hosted-fields-invalid';
const BRAINTREE_VALID_SELECTOR = 'braintree-hosted-fields-valid';
const BRAINTREE_FOCUSED_SELECTOR = 'braintree-hosted-fields-focused';

/**
 * Returns array of filed containers
 * @param {Array} arrayOfFormFields Array of invalid fields
 * @param {Object} ccFields An object of DOM credit card fields
 * @returns {Array} Array of filed containers
 */
function getArrayOfFieldContainers(arrayOfFormFields, ccFields) {
    const arrayOfFieldContainers = [];
    let fieldContainer;

    arrayOfFormFields.forEach(function(fieldName) {
        fieldContainer = ccFields[fieldName];

        if (fieldContainer) {
            arrayOfFieldContainers.push(fieldContainer);
        }
    });

    return arrayOfFieldContainers;
}

/**
 * Sets 'BRAINTREE_ERROR_SELECTOR' selector to the invalid form fields
 * @param {Array} arrayOfInvalidFieldsFrom Array of invalisd fields
 * @param {Object} ccFields An object of DOM credit card fields
 */
function makeFormFieldsInvalid(arrayOfInvalidFieldsFrom, ccFields) {
    const arrayOfInvalidFormFields = getArrayOfFieldContainers(arrayOfInvalidFieldsFrom, ccFields);

    arrayOfInvalidFormFields.forEach(function($field) {
        // remove "field valid" class
        $field.classList.remove(BRAINTREE_VALID_SELECTOR);
        // add "field not valid" class
        $field.classList.add(BRAINTREE_ERROR_SELECTOR);
    });
}

/**
 * Makes all fields invalid
 * @param {Object} ccFields An object of DOM credit card fields
 */
function makeAllFormFieldsInvalid(ccFields) {
    const arrayOfCreditCardFieldNames = Object.keys(ccFields);

    makeFormFieldsInvalid(arrayOfCreditCardFieldNames, ccFields);
}

/**
 * Makes all form fields valid
 */
function makeAllFormFieldsValid() {
    const arrayOfCreditCardFields = creditCardFormFieldsHelper.getCCFields().asArray;

    arrayOfCreditCardFields.forEach(function($field) {
        // remove "field in focuse" class
        $field.classList.remove(BRAINTREE_FOCUSED_SELECTOR);
        // remove "field not valid" class
        $field.classList.remove(BRAINTREE_ERROR_SELECTOR);
        // add "field valid" class
        $field.classList.add(BRAINTREE_VALID_SELECTOR);
    });
}

module.exports = {
    makeFormFieldsInvalid,
    makeAllFormFieldsInvalid,
    makeAllFormFieldsValid
};
