'use strict';

// Braintree SDK model builder, we need to init Braintree Venmo model on this level, since we are using it on step of "venmoAccount.init()"
// and on step of "venmoCheckout.init()".
const braintreeVenmoModelBuilder = require('./braintreesdk/braintreeVenmoModelBuilder');

// components
const venmoAccount = require('./components/venmoAccount');
const venmoCheckout = require('./components/venmoCheckout');

let btVenmoModelResponse;

/**
 * Inits Venmo functionality on the Account Page
 */
function initAccount() {
    venmoAccount.init(btVenmoModelResponse.btVenmoModel);
}

/**
 * Inits Venmo functionality on the Billing Page
 */
function initBillingCheckout() {
    venmoCheckout.init(btVenmoModelResponse.btVenmoModel);
}

/**
 * Inits braintreeVenmoModelBuilder on Venmo level
 */
function init() {
    btVenmoModelResponse = braintreeVenmoModelBuilder.init();
}

module.exports = {
    initAccount,
    initBillingCheckout,
    init
};
