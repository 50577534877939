'use strict';

const helper = require('../../helper');

/**
 * Sets value of credit card hidden fields needed for server side
 * @param {Object} tokenizePayload Tokenize payload
 */
function setCardHiddenFields(tokenizePayload) {
    const tokenizePayloadDetails = tokenizePayload.details;

    document.getElementById('braintreeCreditCardNonce').value = tokenizePayload.nonce;
    document.getElementById('braintreeCardType').value = tokenizePayloadDetails.cardType;
    document.getElementById('braintreeCardMaskNumber').value = tokenizePayloadDetails.lastFour;
    document.getElementById('braintreeCardHolder').value = tokenizePayloadDetails.cardholderName;
}

/**
 * @param {Object} billingAddress billing address
 * @returns {Object} preperad data
 */
function getDataForVerifyCard(billingAddress) {
    let preparedData = {};

    const $customerInfo = document.querySelector('.js-braintree-add-new-card-block');

    if ($customerInfo) {
        preparedData = helper.tryParseJSON($customerInfo.getAttribute('data-customer-info-card'));
    }

    if (Object.keys(preparedData.billingData).length === Object.keys(billingAddress).length) {
        preparedData.billingData = billingAddress;
    }

    return preparedData;
}

/**
 * @param {Object} braintreeCreditCardModel credit card model
 * @param {Object} tokenizePayload payload
 * @param {Object} errorList object with errors
 * @returns {Object} result of validation
 */
function verifyCard3dSecure(braintreeCreditCardModel, tokenizePayload, errorList) {
    const creditCardHelper = require('../helpers/creditCardHelper');

    const prefs = creditCardHelper.getCheckoutBraintreeCreditCardConfigs();
    const is3DSecureFlow = prefs.is3dSecureEnabled || braintreeCreditCardModel.isTrigger3DSecureFlow(tokenizePayload);

    if (prefs.is3DSecureVerificationAccountPage && is3DSecureFlow) {
        // Fill in this data
        const billingData = {};
        const orderAmount = '0.00';
        const customerInfo = getDataForVerifyCard(billingData);

        const nonce = tokenizePayload.nonce;
        const bin = tokenizePayload.details ? tokenizePayload.details.bin : '';

        const braintreeCreditCardSDK = require('../braintreesdk/braintreeCreditCardSDK');

        return braintreeCreditCardSDK.apply3dSecureValidation(nonce,
            bin,
            orderAmount,
            customerInfo.email,
            customerInfo.billingData,
            customerInfo.shippingAdditionalInfo).then(function(payload) {
            if (payload.liabilityShifted) {
                // "Nonce" is changed after successful 3DS validation. We need this line to store the last "nonce" value
                if (tokenizePayload) {
                    // For stored card use case we don't need this param
                    tokenizePayload.nonce = payload.nonce;
                }

                return {
                    threeDSecureDataValidationPayload: payload,
                    btTokenizePayload: tokenizePayload
                };
            }

            // Case when buyer "canceled" 3DS window
            throw new Error(errorList.CARD_VERIFY_ERROR_MESSAGE);
        });
    }

    return {};
}

module.exports = {
    verifyCard3dSecure,
    setCardHiddenFields,
    getDataForVerifyCard
};
