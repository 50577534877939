'use strict';

const DISPLAY_NONE_CLASS = 'd-none';

/**
 * Shows a zero amount SRC button and hides a normal SRC button
 */
function showZeroAmountSrcButton() {
    document.querySelector('.js-v-button-zero-amount').classList.remove(DISPLAY_NONE_CLASS);
    document.querySelector('.js-v-button').classList.add(DISPLAY_NONE_CLASS);
}

/**
 * Hides a zero amount SRC button and show a normal SRC button
 */
function hideZeroAmountSrcButton() {
    document.querySelector('.js-v-button-zero-amount').classList.add(DISPLAY_NONE_CLASS);
    document.querySelector('.js-v-button').classList.remove(DISPLAY_NONE_CLASS);
}

/**
 * Handles whether show or hide zero amount SRC button
 * @param {number} totalAmount Total amount of order
 */
function handleZeroAmountButtonVisibility(totalAmount) {
    if (totalAmount === 0) {
        showZeroAmountSrcButton();
    } else {
        hideZeroAmountSrcButton();
    }
}

/**
 * Adds event listener to the zero amount button on the Cart/Checkout Page
 * @param {DOMElement} $zeroAmountButton DOM element of zero amount SRC button
 * @param {Object} alertHandler Alert handling model
 */
function addZeroAmountButtonEventListener($zeroAmountButton, alertHandler) {
    $zeroAmountButton.addEventListener('click', () => {
        alertHandler.hideAlerts();
        alertHandler.showZeroAmountError();
    });
}

/**
 * Creates a mutation obserber for total amount element
 * @param {DOMElement} totalAmountElement DOM Element that includes the total cost
 */
function initTotalAmountElementObserver(totalAmountElement) {
    const config = { attributes: true, childList: true, subtree: true };

    const observer = new MutationObserver((element) => {
        const totalAmount = Number.parseFloat(element[0].target.textContent.slice(1));

        // Handles whether show or hide zero amount SRC button
        handleZeroAmountButtonVisibility(totalAmount);
    });

    observer.observe(totalAmountElement, config);
}

module.exports = {
    initTotalAmountElementObserver: initTotalAmountElementObserver,
    addZeroAmountButtonEventListener: addZeroAmountButtonEventListener,
    hideZeroAmountSrcButton: hideZeroAmountSrcButton,
    showZeroAmountSrcButton: showZeroAmountSrcButton,
    handleZeroAmountButtonVisibility: handleZeroAmountButtonVisibility
};
