'use strict';

const srcConstants = require('../constants/srcConstants');
const paymentMethodGeneral = require('../../paymentMethodGeneral');

/**
 * Gets SRC flow name(corresponding page)
 * @returns {string} Flow id(page) account/cart/billing
 */
function getSrcFlowName() {
    const isSrcEnabledOnAccountPage = Boolean(document.querySelector('.js-braintree-account-add-src'));
    const isSrcEnabledOnCartPage = Boolean(document.querySelector('.js-braintree-cart-src-button'));
    const isSrcEnabledOnBillingPage = Boolean(document.querySelector('.js-braintree-src-content'));

    let srcFlowName;

    if (isSrcEnabledOnAccountPage) {
        srcFlowName = srcConstants.FLOW_NAME_ACCOUNT;
    } else if (isSrcEnabledOnCartPage) {
        srcFlowName = srcConstants.FLOW_NAME_CART_CHECKOUT;
    } else if (isSrcEnabledOnBillingPage) {
        srcFlowName = srcConstants.FLOW_NAME_BILLING_CHECKOUT;
    }

    return srcFlowName;
}

/**
 * Makes call to checkoutFrom Cart endpoint
 * @param {Object} srcCheckoutFormData SRC form data passed to server side
 * @param {string} email SRC email from response
 * @param {Object} alertHandler Alert handling model
 * @param {string} chooseShippingUrl Link to checkout stage shipping
 * @returns {Promise} call to checkoutFrom Cart endpoint
 */
function checkoutFromCartCall(srcCheckoutFormData, email, alertHandler, chooseShippingUrl) {
    const checkoutSubmitPayment = window.braintreeUrls.checkoutSubmitPayment;
    const redirectUrl = chooseShippingUrl || window.braintreeUrls.placeOrderUrl;
    // Submit customer form with email (CheckoutServices-SubmitCustomer)
    // as we skip step "login via guest/registered user" while express checkout
    // email is set only in case of guest checkout and if email is not already set

    paymentMethodGeneral.submitCustomerForm(email);

    return fetch(checkoutSubmitPayment, {
        method: 'POST',
        body: srcCheckoutFormData
    })
        .then(function(data) {
            if (data.error) {
                alertHandler.handleCheckoutFromCartError(data);
            }

            sessionStorage.setItem('pageState', 'cart');
            window.location.href = redirectUrl;
        })
        .catch(function(error) {
            if (error && error.redirectUrl) {
                window.location.href = error.redirectUrl;
            }
        });
}

module.exports = {
    getSrcFlowName,
    checkoutFromCartCall
};
