'use strict';

/* global braintreeUtils braintree $ ApplePaySession */

// Helpers
const loaderInstance = require('../../loaderHelper');
const applePayHelper = require('../helpers/applePayHelper');
const applePayPaymentProcessingHelper = require('../helpers/applePayPaymentProcessingHelper');
const helper = require('../../helper');

const applePayPaymentProcessingConstants = require('../constants/applePayPaymentProcessingConstants');

// Components
const applePayCart = require('./applePayCart');
const applePayCheckout = require('./applePayCheckout');

const $braintreeApplePayLoader = document.querySelector('.js-braintree-applepay-loader');
const $applePayButtonContainer = document.querySelector('.js-braintree-applepay-button');

const $continueButton = document.querySelector('button.submit-payment');
const applePayEnabledOnCheckout = Boolean(document.querySelector('.js-braintree-applepay-content'));

// global variables
let loader;
let applePayModel;
let applePayButtonConfig;
let alertHandler;

/**
 * Functionality over tokenized payment
 * @param {Object} paymentData Apple Pay payment data
 */
function onTokenizedPayment(paymentData) {
    const applePayFlow = applePayPaymentProcessingHelper.getApplePayFlowId();

    helper.removeActiveSessionPayment();

    switch (applePayFlow) {
        case applePayPaymentProcessingConstants.FLOW_CHECKOUT_NAME:
            applePayCheckout.init(paymentData);
            $continueButton.click();

            break;
        case applePayPaymentProcessingConstants.FLOW_CART_NAME:
            applePayCart.init(paymentData, alertHandler);

            break;
        default:
            break;
    }
}

/**
 * Creates Apple Pay session. Opens and processes Apple Pay payment window.
 */
async function createApplePaySession() {
    // Updates order amount for paymentDataRequest
    await applePayModel.updateApplePayAmount(applePayButtonConfig);

    // Creates a configuration object for creating Apple pay session
    const paymentDataRequest = applePayModel.createPaymentDataRequest(applePayButtonConfig);

    if (applePayButtonConfig.isRequiredBillingContactFields) {
        paymentDataRequest.requiredBillingContactFields = ['postalAddress', 'name'];
    }

    if (applePayButtonConfig.isRequiredShippingContactFields) {
        paymentDataRequest.requiredShippingContactFields = ['postalAddress', 'name', 'phone', 'email'];
    }

    // Creates new Apple Pay session
    const session = new ApplePaySession(1, paymentDataRequest);
    const applePayFlow = applePayPaymentProcessingHelper.getApplePayFlowId();

    if (applePayFlow === applePayPaymentProcessingConstants.FLOW_CART_NAME) {
        // Adds shipping methods based on the address
        session.onshippingcontactselected = function(event) {
            applePayModel.onShippingContactSelected(event, applePayButtonConfig)
                .then((applePayShippingContactUpdate) => {
                    session.completeShippingContactSelection(applePayShippingContactUpdate);
                })
                .catch((error) => {
                    alertHandler.handleApplePayError(error);
                    session.abort();
                });
        };

        // Updates price based on a selected shipping method
        session.onshippingmethodselected = function(event) {
            applePayModel.onShippingMethodSelected(event, applePayButtonConfig)
                .then((applePayShippingMethodUpdate) => {
                    session.completeShippingMethodSelection(applePayShippingMethodUpdate);
                })
                .catch((error) => {
                    alertHandler.handleApplePayError(error);
                    session.abort();
                });
        };
    }

    // Validates merchant website
    session.onvalidatemerchant = function(event) {
        applePayModel.onValidateMerchant(event, applePayButtonConfig)
            .then(function(merchantSession) {
                session.completeMerchantValidation(merchantSession);
            })
            .catch(function(error) {
                alertHandler.handleApplePayError(error);
                session.abort();
            });
    };

    // Tokenizes an Apple Pay payment
    session.onpaymentauthorized = function(event) {
        loader.show();

        applePayModel.onPaymentAuthorized(event)
            .then(function(tokenizedPayload) {
                session.completePayment(ApplePaySession.STATUS_SUCCESS);

                const TokenizedPaymentData = applePayPaymentProcessingHelper.getTokenizedPaymentData(event, tokenizedPayload);

                onTokenizedPayment(TokenizedPaymentData);

                loader.hide();
            })
            .catch(function(error) {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                loader.hide();
                alertHandler.handleApplePayError(error);
            });
    };

    session.begin();
}

/**
 * Call back function to be resolved when Apple Pay payment button is clicked
 */
function onApplePayButtonClick() {
    alertHandler.hideAlerts();

    // Billing flow
    if (applePayEnabledOnCheckout) {
        // Validates email and phone fields on the Billing page
        if (applePayHelper.isBilligFiledsValid()) {
            createApplePaySession();
        }
    // Cart flow
    } else {
        createApplePaySession();
    }
}

/**
 * Init and add Apple Pay on the Cart or Billing page
 */
function initApplePay() {
    loader.show();

    const result = applePayModel.collectFraudData()
        .then(function(fraudDataPayload) {
            applePayHelper.setApplePayDeviceData(fraudDataPayload);
            loader.hide();

            return applePayModel.initApplePay(ApplePaySession)
                .then(function(canMakePayments) {
                    loader.hide();
                    applePayHelper.renderApplePayButton(applePayButtonConfig.applePayConfig.style);

                    if (!canMakePayments) {
                        applePayHelper.makeApplePayButtonDisabled();
                    } else {
                        const $applePayBtn = document.getElementById('apple-pay-btn');

                        $applePayBtn.addEventListener('click', onApplePayButtonClick);
                    }
                });
        });

    result
        .catch(function(error) {
            loader.hide();
            alertHandler.handleApplePayError(error);
        });
}

/**
 * Inits Apple Pay payment processing components
 * @param {Object} braintreeApplePayModel Apple Pay model
 * @param {Constructor} alertHandlingModel Alert handling model
 */
function init(braintreeApplePayModel, alertHandlingModel) {
    loader = loaderInstance($braintreeApplePayLoader);
    applePayModel = braintreeApplePayModel;
    applePayButtonConfig = helper.tryParseJSON($applePayButtonContainer.getAttribute('data-braintree-config'));
    alertHandler = alertHandlingModel;

    if (window.ApplePaySession) {
        initApplePay();
    } else {
        applePayHelper.hideApplePayTab();
    }
}

module.exports = {
    init
};
