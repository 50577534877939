'use strict';

const braintreeCreditCardSDK = require('./braintreeCreditCardSDK');
const braintreeGeneral = require('../../braintreeGeneral');

/* global Promise braintree $ */

// global variables
let btClientInstancePromise;
let orderData;
let sitePrefs = {};

/**
 * Init Braintree Credit Card model
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 * @param {boolean} is3dSecureEnabled 'BRAINTREE_CREDIT_3DSecure_Enabled' site preference value
 * @param {boolean} isFraudToolsEnabled Is fraud tools enabled value
 * @param {boolean} is3dSecureFallback Is fraud tools enabled value
 */
function init(braintreeClientInstancePromise, is3dSecureEnabled, isFraudToolsEnabled, is3dSecureFallback) {
    braintreeCreditCardSDK.init(braintreeClientInstancePromise);

    btClientInstancePromise = braintreeClientInstancePromise;
    sitePrefs = {
        is3dSecureEnabled: is3dSecureEnabled,
        is3dSecureFallback: is3dSecureFallback,
        isFraudToolsEnabled: isFraudToolsEnabled
    };
}

/**
 * Gets required additional shipping info for 3ds
 *
 * @param {Object} orderAddress - User's shipping address
 * @returns {Object} an object with required fields
 */

function getShippingAdditionalInfo(orderAddress) {
    return {
        workPhoneNumber: orderAddress.phone,
        shippingGivenName: orderAddress.recipientName.split(' ').slice(0, -1).join(' '),
        shippingSurname: orderAddress.recipientName.split(' ').slice(-1).join(' '),
        shippingPhone: orderAddress.phone,
        shippingAddress: {
            streetAddress: orderAddress.line1,
            extendedAddress: orderAddress.line2,
            locality: orderAddress.city,
            region: orderAddress.state,
            postalCode: decodeURIComponent(orderAddress.postalCode),
            countryCodeAlpha2: orderAddress.countryCode
        }
    };
}

/**
 * Updates Order data on Checkout
 */
function updateOrderData() {
    fetch(`${window.braintreeUrls.getOrderInfoUrl}?qwe=2344`)
        .then((response) => {
            if (!response.ok) {
                window.location.reload();
            }

            return response.json();
        })
        .then((data) => {
            data.shippingAdditionalInfo = data.shippingAddress ? getShippingAdditionalInfo(data.shippingAddress) : null;
            orderData = data;
        });
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
    let response;

    if (sitePrefs && sitePrefs.isFraudToolsEnabled) {
        response = braintreeGeneral.collectFraudData(btClientInstancePromise);
    } else {
        response = Promise.resolve({
            customMessage: 'Fraud Data collection isn\t enabled via Custom Site Preference'
        });
    }

    return response;
}

/**
 * @param {Object} tokenizationOptions Tokenize options
 */
function addAuthenticationInsight(tokenizationOptions) {
    const braintreeCreditCardConfigs = require('../helpers/creditCardHelper').getCheckoutBraintreeCreditCardConfigs();

    if (braintreeCreditCardConfigs) {
        tokenizationOptions.authenticationInsight = {
            merchantAccountId: braintreeCreditCardConfigs.merchantAccountId
        };
    }
}

/**
 * @param {string} nonce payment nonce value
 * @returns {Object} regulation environment value of false if an error occurs
 */
function getAuthInsightRegulationEnvironment(nonce) {
    const config = require('../helpers/creditCardHelper').getCheckoutBraintreeCreditCardConfigs();
    const token = document.querySelector('#dwfrm_billing [name="csrf_token"]').value;

    return fetch(config.vaultCreditCardUrl, {
        method: 'POST',
        body: `nonce=${nonce}&csrf_token=${token}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).then(response => response.json());
}

/**
 * Launch the 3D Secure login flow, returning a nonce payload.
 * @param {string} nonce Payment method nonce
 * @param {string} bin Payment method bin
 * @param {number} amount Total amount
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {Object} shippingAdditionalInfo Object with shipping info
 * @param {Object} btTokenizePayload Tokenize payload
 * @returns {Object} Object with nonce payload or custom error
 */
function apply3dSecureValidation(nonce, bin, amount, email, billingData, shippingAdditionalInfo, btTokenizePayload) {
    return braintreeCreditCardSDK.apply3dSecureValidation(nonce, bin, amount, email, billingData, shippingAdditionalInfo)
        .then(function(payload) {
            if (payload.liabilityShifted) {
                // "Nonce" is changed after successful 3DS validation. We need this line to store the last "nonce" value
                if (btTokenizePayload) {
                    // For stored card use case we don't need this param
                    btTokenizePayload.nonce = payload.nonce;
                }

                return {
                    threeDSecureDataValidationPayload: payload,
                    btTokenizePayload: btTokenizePayload
                };
            }

            // Case when buyer "canceled" 3DS window
            return {
                customError: '3DS popup was canceled by buyer'
            };
        });
}

/**
 * @param {Object} payload Payload
 * @returns {boolean} Triggered 3DSecure Flow or not
 */
function isTrigger3DSecureFlow(payload) {
    const is3dSecureDisabled = sitePrefs && !sitePrefs.is3dSecureEnabled;
    const is3dSecureFallback = sitePrefs && sitePrefs.is3dSecureFallback;
    const regulationEnvironment = payload.authenticationInsight ? payload.authenticationInsight.regulationEnvironment : '';

    return is3dSecureDisabled && is3dSecureFallback && ['unavailable', 'psd2', 'psdtwo'].includes(regulationEnvironment);
}

/**
 * Process the new Credit Card
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {boolean} isCheckoutPage Is checkout flow flag
 * @param {HostedFields} hfInstance A hosted fields instance
 * @returns {Object} Object with nonce payload or custom error
 */
function processNewCard(email, billingData, isCheckoutPage, hfInstance) {
    const is3dSecureEnabled = sitePrefs && sitePrefs.is3dSecureEnabled;
    const is3dSecureFallback = sitePrefs && sitePrefs.is3dSecureFallback;
    const orderAmount = orderData.amount;
    const shippingAdditionalInfo = orderData.shippingAdditionalInfo;

    let result = hfInstance.tokenize(isCheckoutPage);

    if (is3dSecureEnabled || is3dSecureFallback) {
        const resultTokenize = result;

        result = result
            .then(function(payload) {
                const nonce = payload.btTokenizePayload.nonce;
                const bin = payload.btTokenizePayload.details ? payload.btTokenizePayload.details.bin : '';

                if (is3dSecureFallback && !isTrigger3DSecureFlow(payload.btTokenizePayload)) {
                    return resultTokenize;
                }

                return apply3dSecureValidation(nonce,
                    bin,
                    orderAmount,
                    email,
                    billingData,
                    shippingAdditionalInfo,
                    payload.btTokenizePayload);
            });
    }

    return result;
}

/**
 * Process the stored Credit Card
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {Object} paymentMethodResponse Payment method resonse
 * @returns {Object} Object with nonce payload or custom error
 */
function processStoredCard(email, billingData, paymentMethodResponse) {
    const nonce = paymentMethodResponse.nonce;
    const bin = paymentMethodResponse.details ? paymentMethodResponse.details.bin : '';

    const orderAmount = orderData.amount;
    const shippingAdditionalInfo = orderData.shippingAdditionalInfo;
    const is3dSecureEnabled = sitePrefs && sitePrefs.is3dSecureEnabled;

    let result = Promise.resolve({
        nonce,
        email,
        bin
    });

    // Case when we have a nonce, it is the case when buyer use stored credit card
    // otherwise, it is the case when buyer use new credit card
    if (is3dSecureEnabled || isTrigger3DSecureFlow(paymentMethodResponse)) {
        result = apply3dSecureValidation(nonce,
            bin,
            orderAmount,
            email,
            billingData,
            shippingAdditionalInfo);
    }

    return result;
}

/**
 * In case of session card we can do nothing since the nonce, 3ds, hosted fields validation already was passed
 * @returns {Promise} return Promise with success data
 */
function processSessionCard() {
    return Promise.resolve({});
}

/**
 * Checks whether the basket is valid
 * @param {string} errorMessage Error message
 * @returns {Object} response object
 */
function isBasketDataValid(errorMessage) {
    const response = {
        error: false,
        customErrorMessage: ''
    };

    if (!orderData || orderData.amount === 0) {
        response.error = true;
        response.customErrorMessage = errorMessage;
    }

    return response;
}

/**
 * Get BT client instance which is used currently in the model
 * @returns {Promise} BT client instance promise
 */
function getClientInstancePromise() {
    return btClientInstancePromise;
}

module.exports = {
    init,
    // "tokenize" or "processNewCard", or "processStoredCard"
    // can be called only after excution of "initCreditCardFields"
    processNewCard,
    processStoredCard,
    processSessionCard,
    getClientInstancePromise,
    getAuthInsightRegulationEnvironment,
    addAuthenticationInsight,
    // "isBasketDataValid" can be called only after calling of "updateOrderData"
    isBasketDataValid,
    updateOrderData,
    collectFraudData,
    isTrigger3DSecureFlow
};
