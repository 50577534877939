'use strict';

const PayPalBaseModel = require('./payPalBaseModel');
const PayPalExpressModel = require('./payPalExpressModel');
const PayPalProductModelHelper = require('../helpers/payPalProductPageModelHelper');
const payPalExpressModelHelper = require('../helpers/payPalExpressModelHelper');
const requestsHelper = require('../helpers/payPalRequestsHelperGlobal');

/**
 * PayPal product model constructor
 * @param {Object} alertHandlerModel alert handler model
 * @param {Promise} btClientInstancePromise BT client instance promise
 * @param {string} payPalButtonSelector PayPal button selector
 * @param {Object} payPalConfigurations configs from server (taken from data attr)
 * @param {Object} $csrfToken CSRF token js container
 * @param {Object} $loaderContainer loader js container
 */
function PayPalProductModel(alertHandlerModel,
                            btClientInstancePromise,
                            payPalButtonSelector,
                            payPalConfigurations,
                            $csrfToken,
                            $loaderContainer) {
    // Init PayPalExpressModel constructor
    PayPalExpressModel.call(this,
        alertHandlerModel,
        btClientInstancePromise,
        payPalButtonSelector,
        payPalConfigurations,
        $csrfToken,
        $loaderContainer);

    this.changePMButtonEnabled = payPalConfigurations.changePMButtonEnabled;
}

// ES5 inheritance
PayPalProductModel.prototype = Object.create(PayPalExpressModel.prototype);

// Dummy function to override default behavior and do not execute "GetOrderInfo" call.
// Instead, we will get basket data inside "onOrderCreateCallback"
PayPalProductModel.prototype.onPayPalButtonClickCallback = function(e) {
    PayPalBaseModel.prototype.onPayPalButtonClickCallback.call(this, e);
};

// Add product to the Cart
PayPalProductModel.prototype.onOrderCreateCallback = async function() {
    const regex = /pdp|pvp/g;
    const flow = this.payPalButtonSelector.match(regex).join();

    await requestsHelper.addProductToCart(this.payPalButtonSelector, flow);

    this.basketData = await requestsHelper.getBasketData(this.basketDataUrl);

    const isUseSavedPaypalAddress = this.changePMButtonEnabled && !this.basketData.shippingAddress;

    if (isUseSavedPaypalAddress) {
        this.savedPaypalAddress = payPalExpressModelHelper.getSavedPaypalAddress(this.payPalButtonSelector);
    }

    return PayPalBaseModel.prototype.onOrderCreateCallback.call(this);
};

// On error remove added product from Cart
PayPalProductModel.prototype.onErrorPaymentCallback = function(error) {
    PayPalExpressModel.prototype.onErrorPaymentCallback.call(this, error);

    PayPalProductModelHelper.removeProductFromCart();
};

// On cancel remove added product from Cart
PayPalProductModel.prototype.onCancelPaymentCallback = function() {
    PayPalProductModelHelper.removeProductFromCart();
};

module.exports = PayPalProductModel;
