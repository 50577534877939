'use strict';

module.exports = {
    FLOW_CHECKOUT_NAME: 'checkout',
    FLOW_CART_NAME: 'cart',
    FLOW_NEW_GP_ACCOUNT_NAME: 'newGpAccount',
    INTENT_INITIALIZE: 'INITIALIZE',
    INTENT_SHIPPING_ADDRESS: 'SHIPPING_ADDRESS',
    INTENT_SHIPPING_OPTION: 'SHIPPING_OPTION',
    REASON_SHIPPING_ADDRESS_UNSERVICEABLE: 'SHIPPING_ADDRESS_UNSERVICEABLE',
    REASON_SHIPPING_OPTION_INVALID: 'SHIPPING_OPTION_INVALID'
};
