'use strict';

/**
 * Show PayPal Account button
 */
function showPayPalAccountBtn() {
    const $addPaypalAccountBtn = document.querySelector('.js-add-paypal-account');
    const $paypalAccountWrapper = document.querySelector('.js-braintree-account-paypal-button-wrapper');

    $addPaypalAccountBtn.classList.add('d-none');
    $paypalAccountWrapper.classList.remove('d-none');
}

module.exports = {
    showPayPalAccountBtn
};
