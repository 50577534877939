'use strict';

const PayPalExpressModel = require('../models/payPalExpressModel');
const PaypalAlertHandlerModel = require('../alerthandler/paypalAlertHandlerModel');
const paymentMethodGeneral = require('../../paymentMethodGeneral');
const paypalMinicartButtonBehavior = require('./payPalButtonDisplayBehavior');
const helper = require('../../helper');

const PAYPAL_BUTTON_SELECTOR = '.js-paypal-minicart-button .js-braintree-paypal-cart-button';

let $payPalButton;

/**
 * Init PayPal on the MiniCart Page
 * @returns {void} stop function execution in case if PayPal button wasn't rendered
 */
function initPayPalOnMiniCart() {
    const $paypalMinicartButton = document.querySelector('.js-paypal-minicart-button');

    // Case when buyer remove product from the Cart
    $('body').on('cart:update', function() {
        paypalMinicartButtonBehavior.paypalMinicartButtonBehavior($paypalMinicartButton);
    });

    paypalMinicartButtonBehavior.paypalMinicartButtonBehavior($paypalMinicartButton);

    const payPalConfigurations = helper.tryParseJSON($payPalButton.getAttribute('data-braintree-config'));
    const isPayPalButtonWasInited = helper.tryParseJSON($payPalButton.getAttribute('data-is-inited'));

    // Do not init PayPal button if it was already inited or PayPal configs are empty
    if (isPayPalButtonWasInited || typeof payPalConfigurations !== 'object' || payPalConfigurations === null) {
        return;
    }

    const $csrfToken = document.querySelector('.js-braintree-cart-paypal-buttons-wrap #csrf_token');
    const $loaderContainer = document.querySelector('.js-braintree-paypal-loader');

    const clientInstancePromise = paymentMethodGeneral.createGeneralClientInstancePromise();

    const alertHandler = new PaypalAlertHandlerModel(payPalConfigurations.messages);
    const PayPalExpress = new PayPalExpressModel(alertHandler,
        clientInstancePromise,
        PAYPAL_BUTTON_SELECTOR,
        payPalConfigurations,
        $csrfToken,
        $loaderContainer);

    // Generate Fraud Data if enabled
    PayPalExpress.generateFraudData();

    // Init PayPal button
    PayPalExpress.initPayment();

    // Indicate that PayPal button was already inited.
    $payPalButton.setAttribute('data-is-inited', true);
}

/**
 * Init PayPal button on the MiniCart Page
 */
function init() {
    const $minicartPopover = document.querySelector('.minicart .popover');

    // To avoid execution of this code on checkout page
    if ($minicartPopover) {
        const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                if (mutation.addedNodes.length < 2) {
                    return;
                }

                $payPalButton = document.querySelector(PAYPAL_BUTTON_SELECTOR);

                if ($payPalButton) {
                    initPayPalOnMiniCart();
                }
            });
        });

        observer.observe($minicartPopover, { childList: true });
    }
}

module.exports = {
    init
};
