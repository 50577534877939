'use strict';

const lpmHelper = require('../helpers/lpmHelper');
const braintreeLpmSDK = require('./braintreeLpmSDK');

const lpmConfigs = lpmHelper.getBraintreeLpmConfigs();
// Global variables
let lpmInstance;
let shippingAddress;
let amount;

/**
 * Init Braintree LPM pay model
 * @param {Promise} braintreeClientInstancePromise Braintree client instance promise
 */

function init(braintreeClientInstancePromise) {
    braintreeLpmSDK.init(braintreeClientInstancePromise);
}

/**
 * Inits LPM instance for the next payment actions
 * @returns {Promise} Promise resolved with the 'createLpmClickListener' boolean value
 */
function initLpm() {
    return braintreeLpmSDK.createLpmPayment()
        .then(function(localPaymentInstance) {
            lpmInstance = localPaymentInstance;

            return {
                createLpmClickListener: true
            };
        });
}

/**
 * Updates order data
 * @param {Object} updatedShippingAddress Updates order shipping address from backend
 * @param {number} updatedAmount Updated order amount from backend
 */
function updateLpmOrderData(updatedShippingAddress, updatedAmount) {
    shippingAddress = updatedShippingAddress;
    amount = updatedAmount;
}

/**
 * Returns order shipping address
 * @returns {Object} Shipping address
 */
function getShippingAddress() {
    return shippingAddress;
}

/**
 * Creates and returns object with all options for initiating the lpm payment flow
 * @param {string} paymentMethodName Payment method name
 * @param {string} email Email address
 * @returns {Object} {Object} Options object
 */
function createRequestData(paymentMethodName, email) {
    return Object.assign(braintreeLpmSDK.createRequestData(shippingAddress,
        lpmConfigs,
        paymentMethodName,
        email,
        amount),
    {
        onPaymentStart: function(_, continueCallback) {
            continueCallback();
        }
    });
}

/**
 * Launches the local payment flow and returns a nonce payload
 * @param {Object} reqData Options object
 * @returns {Promise} Promise resolved with a startPaymentPayload
 */
function startPayment(reqData) {
    return braintreeLpmSDK.startPayment(lpmInstance, reqData);
}

module.exports = {
    init,
    initLpm,
    updateLpmOrderData,
    getShippingAddress,
    createRequestData,
    startPayment
};
