'use strict';

const helper = require('../../helper');

/**
 * Returns Braintree LPM configs
 * @returns {Object} Braintree LPM configs
 */
function getBraintreeLpmConfigs() {
    const $braintreeLpmButton = document.querySelector('.js-braintree-lpm-button');

    let braintreeLpmConfigs = {};

    try {
        if ($braintreeLpmButton) {
            braintreeLpmConfigs = helper.tryParseJSON($braintreeLpmButton.getAttribute('data-braintree-config'));
        }
    } catch (error) {
        return braintreeLpmConfigs;
    }

    return braintreeLpmConfigs;
}

module.exports = {
    getBraintreeLpmConfigs
};
