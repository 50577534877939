'use strict';

const braintreeLpmModelBuilder = require('./braintreesdk/braintreeLpmModelBuilder');
const lpmPaymentProcessing = require('./components/lpmPaymentProcessing');

/**
 * Inits LPM on the Billing-Checkout Page
 */
function init() {
    const braintreeLpmModelResponse = braintreeLpmModelBuilder.init();

    lpmPaymentProcessing.init(braintreeLpmModelResponse.btLpmModel);
}

module.exports = {
    init
};
