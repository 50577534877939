'use strict';

const SrcBaseModel = require('./srcBaseModel');
const srcHelper = require('../helpers/srcHelper');
const srcPaymentProcessingHelper = require('../helpers/srcPaymentProcessingHelper');
const helper = require('../../helper');

// See description of "srcBaseModel" for more info
function SrcCartModel(clientInstancePromise, srcButtonConfigurations, $braintreeSrcDeviceDataInput, loader, alertHandler) {
    SrcBaseModel.call(this,
        clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        loader,
        alertHandler);
}

SrcCartModel.prototype = Object.create(SrcBaseModel.prototype);

// See description of "srcBaseModel" for more info
// Add nonce and billing data to the from and submit to the "CheckoutService-SubmitPayment" endpoint
SrcCartModel.prototype.onTokenizeCallback = async function(srcPayload) {
    SrcBaseModel.prototype.onTokenizeCallback.call(this, srcPayload);

    this.loader.show();
    // Checking if preselected shipping option delivers to the chosen shipping address

    const billingAddress = srcPayload.billingAddress;
    const city = billingAddress.locality;
    const state = billingAddress.region;
    const country = billingAddress.countryCode;
    const postalCode = billingAddress.postalCode;
    const addressQueryString = `city=${city.replace(/\s/g, '%20')}&stateCode=${state}&countryCode=${country}&postalCode=${postalCode}`;
    const paymentMethodId = window.braintreeConstants.PAYMENT_METHOD_NOT_SUPPORTING_SHIPPING_OPTIONS;
    const url = `${window.braintreeUrls.getApplicableShippingOptions}?paymentMethodId=${paymentMethodId}&${addressQueryString}`;

    const shippingOptions = await helper.getApplicableShippingOptions(url);

    let chooseShippingUrl;

    if (!shippingOptions.find((shippingOption) => shippingOption.selected === true)) {
        chooseShippingUrl = `${window.braintreeUrls.chooseShippingUrl}&errorId=error.shippingoption.invalid`;
    }

    // Proceeding with checkout
    const $srcButton = document.querySelector('.js-braintree-src-button');

    srcPayload.billingAddressString = srcHelper.createSrcBillingAddress(srcPayload.billingAddress, srcPayload.userData);

    const paymentMethodName = helper.tryParseJSON($srcButton.getAttribute('data-braintree-config')).paymentMethodName;
    const srcFormData = srcHelper.createSrcFormData(srcPayload, paymentMethodName);
    const srcCheckoutFormData = srcHelper.createSrcCheckoutFormData(srcFormData, srcPayload, window.braintreeConstants.PAGE_FLOW_CART);

    srcPaymentProcessingHelper.checkoutFromCartCall(srcCheckoutFormData, srcPayload.userData.userEmail, this.alertHandler, chooseShippingUrl)
        .always(function() {
            this.loader.hide();
        });
};

module.exports = SrcCartModel;
