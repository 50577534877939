'use strict';

const helper = require('../../helper');

// Global variables
let googlePayBillingAddressData;
let googlePayNonce;
let googlepayPaymentType;
let googlePayCardDescription;

const $googlepayNonce = document.getElementById('braintreeGooglePayNonce');
const $googlepayCardDescription = document.getElementById('braintreeGooglePayCardDescription');
const $braintreeGooglepayPaymentType = document.getElementById('braintreeGooglepayPaymentType');
const $googlePayBillingAddress = document.getElementById('braintreeGooglePayBillingAddress');

/**
 * Process Google Pay on the Checkout Page
 */
function processCheckoutFlow() {
    $googlepayNonce.value = googlePayNonce;
    $googlepayCardDescription.value = googlePayCardDescription;
    $braintreeGooglepayPaymentType.value = googlepayPaymentType;

    // Updating Storefront Billing Form data with GooglePay Billing
    const storeFrontBillingData = helper.getUpdatedStoreFrontBillingData(googlePayBillingAddressData);

    $googlePayBillingAddress.value = JSON.stringify(googlePayBillingAddressData);

    helper.updateBillingFormValues(storeFrontBillingData);
}

/**
 * Inits Google Pay checkout components
 * @param {string} nonce Payment method nonce
 * @param {string} description Google Pay card description
 * @param {string} paymentType Payment method type
 * @param {Object} billingAddressData Billing address data object
 */
function init(nonce, description, paymentType, billingAddressData) {
    googlePayBillingAddressData = billingAddressData;
    googlePayNonce = nonce;
    googlepayPaymentType = paymentType;
    googlePayCardDescription = description;

    processCheckoutFlow();
}

module.exports = {
    init
};
