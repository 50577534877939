'use strict';

const PayPalProductViewPageModel = require('../models/payPalProductModel');
const PaypalAlertHandlerModel = require('../alerthandler/paypalAlertHandlerModel');

const payPalButtonDisplayBehavior = require('./payPalButtonDisplayBehavior');
const braintreePayPalSDK = require('../braintreesdk/braintreePayPalSDK');
const helper = require('../../helper');

/**
 * Init PayPal button on the Product View Page
 * @param {Promise} clientInstancePromise Client Instance Promise
 */
function init(clientInstancePromise) {
    const PP_BTN_SELECTOR = '.js-braintree-paypal-cart-button';

    const pvpModels = [];

    let isVaultMode;
    let payPalConfigurations;

    const paypalButtonContainers
        = document.querySelectorAll('.quick-view-dialog .js-braintree-pvp-button, .quick-view-dialog .js-braintree-pvp-button-global');

    if (paypalButtonContainers) {
        paypalButtonContainers.forEach((container, index) => {
            const $payPalButton = container.querySelector(PP_BTN_SELECTOR);
            const $loaderContainer = container.querySelector('.js-braintree-paypal-loader');
            const $csrfToken = container.querySelector('.js-braintree-cart-paypal-buttons-wrap #csrf_token');

            if ($payPalButton) {
                $payPalButton.classList.add(`${PP_BTN_SELECTOR.slice(1)}-${index}-pvp`);

                const selector = `.${Array.from($payPalButton.classList).join('.')}`;

                payPalConfigurations = helper.tryParseJSON($payPalButton.getAttribute('data-braintree-config'));
                isVaultMode = payPalConfigurations.options.flow !== 'checkout';

                const alertHandler = new PaypalAlertHandlerModel(payPalConfigurations.messages);

                const PayPalProductViewPage = new PayPalProductViewPageModel(alertHandler,
                    clientInstancePromise,
                    selector,
                    payPalConfigurations,
                    $csrfToken,
                    $loaderContainer);

                // Generate Fraud Data if enabled
                PayPalProductViewPage.generateFraudData();

                pvpModels.push(PayPalProductViewPage);
            }
        });

        // Init PayPal buttons
        braintreePayPalSDK.payPalButtonRendering(clientInstancePromise,
            isVaultMode,
            payPalConfigurations,
            pvpModels);

        // Init behavior for hide/show PayPal button
        payPalButtonDisplayBehavior.initProductViewPage();
    }
}

module.exports = {
    init
};
