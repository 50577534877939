'use strict';

const applePayHelper = require('../helpers/applePayHelper');
const braintreeGeneral = require('../../braintreeGeneral');
const braintreeApplePayModel = require('./braintreeApplePayModel');

const applePayErrorMessages = require('../constants/errorMessages');

/**
 * Initialize Braintree Apple Pay Model. Pattern Builder is used
 * @returns {Object} Object with booleam "error" flag and "message" attribute with error message
 */
function init() {
    const braintreeApplePayConfigs = applePayHelper.getBraintreeApplePayConfigs();
    const response = {
        error: false,
        message: '',
        btApplePayModel: null,
        btApplePayConfigs: null
    };

    let clientToken;
    let btClientInstancePromise;

    if (braintreeApplePayConfigs) {
        clientToken = braintreeApplePayConfigs.clientToken;
        btClientInstancePromise = braintreeGeneral.createClientInstance(clientToken);

        braintreeApplePayModel.init(btClientInstancePromise,
            braintreeApplePayConfigs.isFraudToolsEnabled);

        response.btApplePayModel = braintreeApplePayModel;
        response.btApplePayConfigs = braintreeApplePayConfigs;
    } else {
        response.error = true;
        response.message = applePayErrorMessages.NO_APPLE_PAY_BRAINTREE_CONFIGURATIONS;
    }

    return response;
}

module.exports = {
    init
};
