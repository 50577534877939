'use strict';

/* global Promise */

const braintreeCreditCardSDK = require('./braintreeCreditCardSDK');
const creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');
const braintreeCreditCardSdkHelper = require('../helpers/braintreeCreditCardSdkHelper');
const helper = require('../../helper');

/**
 * Returns a hosted fields config object
 * @returns {Object} A hosted fields config object
 */
const getHostedFieldsConfig = () => {
    const $braintreeCreditCardsFields = document.querySelector('.js-braintree-credit-card-fields');

    return helper.tryParseJSON($braintreeCreditCardsFields.getAttribute('data-braintree-config'));
};

/**
 * HostedFields constructor
 */
function HostedFieldsModel() {
    this.sdkHfInstance = null;
    this.fieldsOptions = null;
    this.hostedFieldsConfigs = getHostedFieldsConfig();
    this.verifiedCardsArray = [];
}

/**
 * Returns an object of hosted fields initiated to the DOM
 * @returns {Object} An object of dom elements
 */
HostedFieldsModel.prototype.getCcFields = function() {
    return Object.keys(this.fieldsOptions.configs).reduce((accum, curr) => {
        const selector = this.fieldsOptions.configs[curr].selector;

        accum[selector.slice(1)] = document.querySelector(selector);

        return accum;
    }, {});
};

/**
 * Sets an UUID of a Credit card if it is already verified
 * @param {string} ccUUID A Credit card UUID
 */
HostedFieldsModel.prototype.setVerifiedCardUUIDtoArray = function(ccUUID) {
    this.verifiedCardsArray.push(ccUUID);
};

/**
 * Returns whether a given credit card need to be verify
 * @param {string} ccUUID A Credit card UUID
 * @returns {boolean} True/False
 */
HostedFieldsModel.prototype.isCcReVerifyRequired = function(ccUUID) {
    return this.hostedFieldsConfigs.isCcReVerifyEnabled && !this.verifiedCardsArray.includes(ccUUID);
};

/**
 * Initiates an observer to handle a credit card re-verify, when it is sucessfully occurred
 * @param {string} ccUUID A credit card UUID
 */
HostedFieldsModel.prototype.initPaymentDetailsSelectorObserver = function(ccUUID) {
    const that = this;

    const observer = new MutationObserver(function() {
        if (that.hostedFieldsConfigs.isCcReVerifyEnabled) {
            if (!that.verifiedCardsArray.includes(ccUUID)) {
                that.setVerifiedCardUUIDtoArray(ccUUID);
            }

            creditCardFormFieldHelper.hideCardElements(creditCardFormFieldHelper.getCcCvvField().asArray);
            creditCardFormFieldHelper.showCardElements(creditCardFormFieldHelper.getCcCvvToDisplayField().asArray);
            document.getElementById('braintreeCvvOnlyNonce').value = '';
        }
    });

    observer.observe(document.querySelector('.js-braintree-payment-details'), { childList: true });
};

/**
 * Validates whether the sdkHfInstance exist
 * @returns {Object} An object
 */
HostedFieldsModel.prototype.sdkHfInstanceValidation = function() {
    if (!this.sdkHfInstance) {
        return {
            exist: false,
            errorMessage: this.hostedFieldsConfigs.customMessages.HOSTED_FIELDS_INSTANCE_NOT_DEFINE
        };
    }

    return {
        exist: true
    };
};

/**
 * Sets whether a re-verify or basic hosted fields options to the instance
 * @param {Object} options A hosted fields options
 */
HostedFieldsModel.prototype.setHostedFieldsOptions = function(options) {
    this.fieldsOptions = options;
};

/**
 * Initiates a hosted fileds instance
 * @returns {HostedFields} A Hosted fields instance
 */
HostedFieldsModel.prototype.initHostedFields = function() {
    const that = this;

    return braintreeCreditCardSDK.createHostedFields(this.fieldsOptions)
        .then(function(hfInstance) {
            // init hosted fields instance globally
            that.sdkHfInstance = hfInstance;

            return hfInstance;
        });
};

// eslint-disable-next-line valid-jsdoc
/**
 * Tokenizes previouselly filled hosted Credit Card fields and returns a nonce payload
 * @returns {Promise} Promise with { error: false, result: 'ok'} inside in case of positive response and
 * { error: true} in case of negative (reject, case)
 */
HostedFieldsModel.prototype.tokenize = function(isCheckoutPage) {
    const btCreditCardModel = require('../braintreesdk/braintreeCreditCardModel');

    let tokenizationOptions;

    if (isCheckoutPage) {
        // Pull billing address from Storefront. Passed data will be tokenized and sent to Braintree
        tokenizationOptions = braintreeCreditCardSdkHelper.createTokenizationOptions();
    } else {
        tokenizationOptions = {};
    }

    btCreditCardModel.addAuthenticationInsight(tokenizationOptions);

    return braintreeCreditCardSDK.tokenize(this.sdkHfInstance, tokenizationOptions)
        .then(function(payload) {
            return {
                btTokenizePayload: payload
            };
        });
};

/**
 * Tokenizes previouselly filled hosted Credit Card fields and returns a nonce payload
 * @returns {Promise} Promise with { error: false, result: 'ok'} inside in case of positive response and
 * { error: true} in case of negative (reject, case)
 */
HostedFieldsModel.prototype.tokenizeCvv = function() {
    return braintreeCreditCardSDK.tokenize(this.sdkHfInstance)
        .then(function(cvvPayload) {
            return {
                btTokenizePayload: cvvPayload
            };
        });
};

/**
 * Clear Credit Card hosted fields
 */
HostedFieldsModel.prototype.clearHostedFields = function() {
    const that = this;

    Object.keys(this.fieldsOptions.configs).forEach(option => {
        that.sdkHfInstance.clear(option);
    });
};

/**
 * Cleanly remove anything set up to the Hosted fields
 */
HostedFieldsModel.prototype.teardown = function() {
    this.sdkHfInstance.teardown();
};

/**
 * Returns An object contains the hosted fileds options for basic as weel as re-verify flow
 * @returns {Object} The hosted fields options
 */
HostedFieldsModel.prototype.getFieldsOptions = function() {
    const braintreeCvvElId = '#braintreeCvv';
    const reverifyFlow = {
        cvv: {
            selector: braintreeCvvElId,
            placeholder: this.hostedFieldsConfigs.customMessages.REVERIFY_CC
        }
    };

    const ccFields = creditCardFormFieldHelper.getCCFields().asObject;

    let cardOwnerPlaceholder = '';
    if (ccFields.braintreeCardOwner && ccFields.braintreeCardOwner.getAttribute('data-placeholder')) {
        cardOwnerPlaceholder = ccFields.braintreeCardOwner.getAttribute('data-placeholder');
    }

    let expirationDatePlaceholder = '';
    if (ccFields.braintreeExpirationDate && ccFields.braintreeExpirationDate.getAttribute('data-placeholder')) {
        expirationDatePlaceholder = ccFields.braintreeExpirationDate.getAttribute('data-placeholder');
    }

    let cardNumberPlaceholder = '';
    if (ccFields.braintreeCardNumber && ccFields.braintreeCardNumber.getAttribute('data-placeholder')) {
        cardNumberPlaceholder = ccFields.braintreeCardNumber.getAttribute('data-placeholder');
    }

    let basicFlow = {
        cardholderName: {
            selector: '#braintreeCardOwner',
            placeholder: cardOwnerPlaceholder,
        },
        number: {
            selector: '#braintreeCardNumber',
            placeholder: cardNumberPlaceholder,
        },
        cvv: {
            selector: braintreeCvvElId,
            placeholder: '123',
        },
        expirationDate: {
            selector: '#braintreeExpirationDate',
            placeholder: expirationDatePlaceholder,
        }
    };

    const styles = creditCardFormFieldHelper.getHostedFieldsStyles();

    return {
        reverifyFlowConfigs: reverifyFlow,
        basicFlowConfigs: basicFlow,
        styles: styles
    };
};

module.exports = HostedFieldsModel;
