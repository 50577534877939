'use strict';

const helper = require('../../helper');

let paymentData;

/**
 * Process Apple Pay on the Checkout page
 */
function processCheckoutFlow() {
    document.getElementById(('braintreeApplePayNonce')).value = paymentData.nonce;
    document.getElementById(('braintreeApplePayBillingAddress')).value = JSON.stringify(paymentData.billingAddress);

    // Updating Storefront Billing Form data with ApplePay Billing
    const storeFrontBillingData = helper.getUpdatedStoreFrontBillingData(paymentData.billingAddress);

    helper.updateBillingFormValues(storeFrontBillingData);
}

/**
 * Inits Apple Pay checkout components
 * @param {Object} data Apple Pay payment data
 */
function init(data) {
    paymentData = data;

    processCheckoutFlow();
}

module.exports = {
    init
};
