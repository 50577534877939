'use strict';

const helper = require('../../helper');

const $btSavePaypalAccountContainer = document.getElementById('braintreeSavePaypalAccountContainer');
const $paypalContent = document.querySelector('.js-braintree-paypal-content');
const $btPayPalBillingButton = document.querySelector('.js-braintree-paypal-billing-button');

/**
 * Hides PayPal Smart Button functionality inside PayPal tab
 */
function hidePaypalSmartButtonFunctionality() {
    $btSavePaypalAccountContainer.style.display = 'none';
    $btPayPalBillingButton.classList.add('d-none');
    $paypalContent.setAttribute('data-paypal-is-hide-continue-button', false);
}

/**
 * Shows PayPal Smart Button functionality inside PayPal tab
 */
function showPaypalSmartButtonFunctionality() {
    $btPayPalBillingButton.classList.remove('d-none');
    $paypalContent.setAttribute('data-paypal-is-hide-continue-button', true);
}

/**
 * Handles a behavior inside PayPal tab, when session PayPal account was deleted
 */
function handlePaypalTabBehavior() {
    const $payPalAccountList = document.getElementById('braintreePaypalAccountsList');
    const $defaultOption = helper.getOptionByDataDefault('#braintreePaypalAccountsList');
    const $selectedOption = helper.getSelectedOption($payPalAccountList);
    const isSelectedPaypalAccount = $selectedOption.value !== 'newaccount';
    const paypalButtonConfigs = helper.tryParseJSON($btPayPalBillingButton.getAttribute('data-braintree-config'));
    const isChangePmButton = paypalButtonConfigs.changePMButtonEnabled;

    // Case when buyer selected another PM (CC,GP..)
    if ($defaultOption && !isSelectedPaypalAccount) {
        // Shows ChangePM button if buyer has already saved PP account and ChangePM button is enabled
        if (isChangePmButton) {
            $btSavePaypalAccountContainer.style.display = 'block';

            showPaypalSmartButtonFunctionality();
        // Shows default PayPal account
        } else {
            $defaultOption.selected = true;

            hidePaypalSmartButtonFunctionality();
        }
    // Case when buyer selected PayPal account from paypalAccountList
    } else if (isSelectedPaypalAccount) {
        hidePaypalSmartButtonFunctionality();
    // Case when buyer does not have saved PayPal account
    } else {
        const $btUsedPayPalAccount = document.querySelector('.js-braintree-used-paypal-account');

        $btUsedPayPalAccount.classList.remove('used-paypal-account');
        $btUsedPayPalAccount.classList.add('used-paypal-account-hide');

        showPaypalSmartButtonFunctionality();
    }

    [].forEach.call($payPalAccountList, function(el) {
        if (el.style.display === 'none') {
            el.style.display = 'block';
        }
    });
}

module.exports = {
    handlePaypalTabBehavior,
    hidePaypalSmartButtonFunctionality,
    showPaypalSmartButtonFunctionality
};
